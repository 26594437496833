@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&subset=cyrillic-ext');
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@300;400;600&display=swap');
@font-face {
  font-family: 'AvenirNextCyr';
  src: url('../webfonts/AvenirNextCyr-Light.eot');
  src: url('../webfonts/AvenirNextCyr-Light.eot?#iefix') format('embedded-opentype'),
    url('../webfonts/AvenirNextCyr-Light.woff2') format('woff2'),
    url('../webfonts/AvenirNextCyr-Light.woff') format('woff'),
    url('../webfonts/AvenirNextCyr-Light.ttf') format('truetype'),
    url('../webfonts/AvenirNextCyr-Light.svg#AvenirNextCyr-Light') format('svg');
  font-weight: 300;
  font-style: normal;
}
