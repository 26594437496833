header {
  z-index: 9;
  position: relative;
  background-color: $agiva-header-background;
  nav {
    padding: rem-calc(20 0);
    @include breakpoint(medium down) {
      background-color: $white;
    }
    .menu {
      &.dropdown {
        a {
          color: $white;
          font-size: rem-calc(14);
          transition: all 0.4s ease;
          &:hover, &:focus {
            color: scale-color($primary-color, $lightness: -10%);
          }
        }
        .submenu {
          a {
            color: scale-color($primary-color, $lightness: -10%);
            background-color: transparent;
            &:hover {
              color: $black;
            }
          }
        }
      }
      &:not(.locales):not(.submenu) {
        a {
          text-transform: uppercase;
          letter-spacing: -0.5px;
        }
        &>li {
          &:first-child {
            @include breakpoint(medium) {
              & > a {
                padding-left: 0;
              }
            }
          }
        }
      }
      &.locales {
        // margin-top: rem-calc(4);
        li {
          margin-left: rem-calc(6);
        }
        a {
          border: 1px solid $white;
          border-radius: 3px;
          padding: rem-calc(6 8);
        }
        .active {
          a {
            color: #84bd00;
            background-color: $white;
          }
        }
        @include breakpoint(small only) {
          justify-content: center;
        }
      }
    }
    .logo {
      @include breakpoint(medium) {
        padding-right: rem-calc(35);
      }
      a {
        padding-top: rem-calc(4);
        display: inline-block;
        // text-align: center;
        img {
          height: 28px;
        }
      }
    }
  }
  .title-bar-title {
    flex: 1;
    a {
      img {
        width: rem-calc(86);
      }
    }
  }
  .title-bar-right {
    display: flex;
  }
  .title-bar-hamburger {
    width: 50%;
    text-align: right;
  }
  .cell {
    &:last-child {
      display: flex;
      ul {
        display: inline-flex;
      }
    }
  }
  .button.buy-now-button {
    height: 30px;
    display: inline-flex;
    margin-bottom: 0;
    padding: rem-calc(6 22 5 18);
    color: $white;
    border: solid 1px $white;
    border-radius: 3px;
    margin-right: rem-calc(15);
    font-weight: 300;
    font-size: rem-calc(14);
    white-space: nowrap;
    @include breakpoint(medium) {
      font-size: rem-calc(16);
    }
  }
  .buy-now-button {
    svg {
      transform: scale(0.7);
      margin-right: rem-calc(10);
      path {
        fill: $white;
        transition: fill 0.25s;
      }
    }
    &:hover {
      background-color: $white !important;
      border-color: $white !important;
      color: $primary-color !important;
      svg {
        path {
          fill: $primary-color;
        }
      }
    }
  }
}
