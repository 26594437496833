footer {
  color: #bababa;
  background-color: $white;
  font-weight: 300;
  font-size: rem-calc(11);
  padding: rem-calc(15 0 25);
  position: relative;
  z-index: 15;
  @include breakpoint(small only) {
    text-align: center;
    font-size: rem-calc(12);
  }
  a {
    color: #bababa;
    transition: all 0.4s ease;
    font-weight: 300;
    font-size: rem-calc(11);
    padding: rem-calc(5);
    &:hover {
      color: $dark-gray;
    }
    @include breakpoint(small only) {
      font-size: rem-calc(12);
    }
  }
  .vertical {
    @include breakpoint(small only) {
      margin-bottom: rem-calc(16);
    }
  }
  .menu {
    @include breakpoint(small only) {
      justify-content: center;
      margin-bottom: rem-calc(16);
    }
  }
  .grid-x {
    justify-content: space-between;
    .cell {
      flex-direction: column;
      .menu__title {
        font-weight: 600;
      }
    }
    a {
      padding: rem-calc(5);
      white-space: nowrap;
    }
  }
  .icons {
    padding-top: rem-calc(15);
    img {
      height: 19px;
      opacity: 0.4;
      transition: opacity 0.3s;
    }
    a {
      &:hover {
        img {
          opacity: 1;
        }
      }
    }
  }
  .copyright {
    padding: rem-calc(5 15 15);
    @include breakpoint(small only) {
      text-align: center;
    }
  }
}

.HomePage {
  footer {
    /* homepage only */
    position: absolute;
    bottom: 0;
    width: 100%;
    background: white;
    z-index: 10;
    @include breakpoint(small only) {
      position: static;
    }
    /* -------------- */
  }
}