$header-serif: Georgia, serif;

$hero-height: 65vh;//rem-calc(465);
$hero-height-mobile: auto;//35vh;
$roses-color: #f8c1d9;//#d43396;
$dark-roses-color: #d43396;
$flower-color: #dda4fa;//#9063cd;
$dark-flower-color: #a554c0;
$olives-color: #d6ea97;//#bfb800;
$dark-olives-color: #bfb800;
//$dark-olives-color: #71692b;
$senses-color: #ace0ee;
$dark-senses-color: #098db3;
$fruit-color: #add6f2;
$dark-fruit-color: #0971ce;

$owl-item-height: rem-calc(375);
$owl-item-height-mobile: rem-calc(275);
$owl-item-height-mobile-cta: rem-calc(150);

body {
  position: relative;
  opacity: 0;
}
.button {
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease, box-shadow 0.3s ease;
  font-family: 'Fira Sans', sans-serif;
}
.owl-carousel .owl-item div > p,
h1 p,
h2 p {
  margin-bottom: 0 !important;
  line-height: 1.2 !important;
  color: inherit !important;
  font-size: inherit !important;
}
.owl-carousel .owl-item div > p {
  line-height: 1.6 !important;
}

.scroll-icon {
  padding: rem-calc(20);
  font-size: rem-calc(13);
}
.tabs {
  border: none;
  .tabs-title {
    position: relative;
    width: 25%;
    background-color: $white;
    @include breakpoint(small only) {
      width: 100%;
    }
    a {
      border: 1px solid $medium-gray;
      border-right: none;
      padding-top: rem-calc(36);
      padding-bottom: rem-calc(36);
      &:hover {
        //opacity: 0.5;
      }
    }
    img {
      max-height: 28px;
    }
    svg {
      max-height: 28px;
    }
    &:last-child {
      a {
        border-left: 1px solid $medium-gray;
        // padding: rem-calc(45 0);
      }
    }
    &.is-active {
      .svg {
        path {
          fill: $white;
        }
      }
      &.roses {
        background-color: $roses-color;
        &:before {
          border-bottom-color: $roses-color;
        }
        a {
          border-color: $roses-color;
        }
        .svg {
          path {
            fill: $dark-roses-color;
          }
        }
      }
      &.flower {
        background-color: $flower-color;
        &:before {
          border-bottom-color: $flower-color;
        }
        a {
          border-color: $flower-color;
        }
        .svg {
          path {
            fill: $dark-flower-color;
          }
        }
      }
      &.olives {
        background-color: $olives-color;
        &:before {
          border-bottom-color: $olives-color;
        }
        a {
          border-color: $olives-color;
        }
        .svg {
          path {
            fill: $dark-olives-color;
          }
        }
      }
      &.senses {
        background-color: $senses-color;
        &:before {
          border-bottom-color: $senses-color;
        }
        a {
          border-color: $senses-color;
        }
        .svg {
          path {
            fill: $dark-senses-color;
          }
        }
      }
      &.fruit {
        background-color: $fruit-color;
        &:before {
          border-bottom-color: $fruit-color;
        }
        a {
          border-color: $fruit-color;
        }
        .svg {
          path {
            fill: $dark-fruit-color;
          }
        }
      }
      &:before {
        width: 0;
        height: 0;
        border-left: 11px solid transparent;
        border-right: 11px solid transparent;
        border-bottom: 11px solid red;
        position: absolute;
        content: "";
        display: block;
        top: -11px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}
.tabs-wrapper,
.tabs-content-wrapper {
  padding: 0;
}
#product-series-l {
  &>li {
    &:nth-child(3),
    &:nth-child(4) {
      a {
        //pointer-events: none;
      }
    }
  }
}
@keyframes progressBar {
  from {width: 0;}
  to {width: 100%;}
}
.mobile-home-banner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.tabs-content {
  // max-height: calc(100vh - 120px - 115px - 60px);
  border: none;
  @include breakpoint(small only) {
    min-height: $hero-height-mobile;
  }
  @include breakpoint(medium) {
    height: $hero-height;
    max-height: calc(100vh - 120px - 115px - 60px);
  }
  @include breakpoint(large) {
    max-height: calc(100vh - 120px - 115px - 90px);
  }
  .tabs-panel {
    height: $hero-height;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    overflow: hidden;
    @include breakpoint(small only) {
      height: $hero-height-mobile;
      padding-top: rem-calc(40);
      min-height: 70vh;
      // background-position: left -30vw center;
      &>a {
        display: block;
      }
      .slide-row {
        position: relative;
      }
    }
    @include breakpoint(medium) {
      opacity: 0;
      transition: opacity 0.5s ease;
      position: relative;
      &.is-active {
        opacity: 1;
        &:before {
          content: "";
          display: block;
          position: absolute;
          //width: 100%;
          height: 3px;
          top: 0;
          left: 0;
          background-color: $primary-color;
          //z-index: 1998;
          animation-name: progressBar;
          animation-duration: 10s;
          animation-timing-function: linear;
        }
      }
    }
    .tagline {
      padding: 5% 0 5% 0;
      min-height: $hero-height;
      @include breakpoint(small only) {
        min-height: $hero-height-mobile;
        padding-top: 0;
      }
      h1 {
        font-size: rem-calc(26);
        line-height: 1.2;
        @include breakpoint(small only) {
          font-size: rem-calc(22);
        }
      }
      .taglogo {
        height: rem-calc(55);
        width: auto;
        margin-bottom: rem-calc(25);
        @include breakpoint(small only) {
          width: 45vw;
          height: auto;
          margin-bottom: rem-calc(15);
        }
      }
      .column {
        &.left-bottom {
          display: flex;
          flex-flow: column;
          justify-content: flex-end;
          align-items: flex-start;
        }
      }
    }
    .productline {
      padding: 0;
      min-height: $hero-height;
      @include breakpoint(small only) {
        min-height: $hero-height-mobile;
      }
      &>.row {
        position: relative;
      }
      .slide-heading {
        position: absolute;
        top: 0;
        right: rem-calc(16);
        padding: 8% 0 8% 0;
        h1 {
          font-size: rem-calc(26);
          line-height: 1.2;
          @include breakpoint(small only) {
            font-size: rem-calc(22);
          }
        }
        .taglogo {
          height: rem-calc(55);
          width: auto;
          margin-bottom: rem-calc(25);
          @include breakpoint(small only) {
            width: 55vw;
            height: auto;
            margin-bottom: rem-calc(15);
          }
        }
        &.right-bottom {
          display: flex;
          flex-flow: column;
          justify-content: flex-end;
          align-items: flex-end;
        }
      }
    }
  }
}
.home-product {
  height: 100%;
  &>.row {
    height: 100%;
  }
  .box-content {
    h1 {
      font-family: $header-serif;
      font-style: italic;
      font-size: rem-calc(36);
      color: $black;
      @include breakpoint(small only) {
        font-size: rem-calc(28);
      }
    }
    hr {
      border: none;
      width: rem-calc(140);
      background-color: $white;
      height: 1px;
      margin: rem-calc(40 0);
      @include breakpoint(small only) {
        margin: rem-calc(15 0);
      }
    }
    p {
      color: #636363;
      font-size: rem-calc(14);
      margin-bottom: rem-calc(38);
      padding-right: 15%;
    }
    a {
      &.button {
        color: $black;
        font-size: rem-calc(12);
        text-transform: uppercase;
        border-color: $black;
        padding: rem-calc(14 20);
      }
    }
  }
  .content-bg {
    background-repeat: no-repeat;
    background-position: right center;
    background-size: cover;
  }
  .product-bg {
    background-repeat: no-repeat;
    background-position: right center;
    background-size: cover;
    display: flex;
    &>.row {
      flex: 0 0 100%;
    }
  }
  .content-bg {
    padding: 0;
    .product-title,
    .product-content {
      flex: 1 1 rem-calc(600);
      max-width: rem-calc(600);
    }
    .product-title {
      padding: rem-calc(35 0 30 15);
      svg,
      .svg,
      img {
        // max-width: rem-calc(165);
        max-height: rem-calc(36);
      }
      @include breakpoint(small only) {
        padding: rem-calc(25 0 20);
        text-align: center;
      }
    }
  }
  .single-product {
    padding-bottom: rem-calc(30);
  }
  &.roses {
    .content-bg {
      background-color: #f8e7f1;
      background-image: url('../img/roses-home-content-bg.jpg');
    }
    .product-bg {
      background-color: #f8f8f8;
      background-image: url('../img/roses-home-product-bg.jpg');
    }
    .box-title {
      background-color: $roses-color;
    }
  }
  &.flower {
    .content-bg {
      background-color: #f6dcff;
      background-image: url('../img/flower-home-content-bg.jpg');
    }
    .product-bg {
      background-color: #f3f3f3;
      background-image: url('../img/flower-home-product-bg.jpg');
    }
    .box-title {
      background-color: $flower-color;
    }
  }
  &.olives {
    .content-bg {
      background-color: #e7f4c8;
      background-image: url('../img/olives-home-content-bg.jpg');
    }
    .product-bg {
      background-color: #f3f3f3;
      background-image: url('../img/olives-home-product-bg.jpg');
    }
    .box-title {
      background-color: $olives-color;
    }
  }
  &.senses {
    .content-bg {
      background-color: #e7f4c8;
      background-image: url('../img/olives-home-content-bg.jpg');
    }
    .product-bg {
      background-color: #f3f3f3;
      background-image: url('../img/olives-home-product-bg.jpg');
    }
    .box-title {
      background-color: $senses-color;
    }
  }
  &.fruit {
    .content-bg {
      background-color: #e7f4c8;
      background-image: url('../img/olives-home-content-bg.jpg');
    }
    .product-bg {
      background-color: #f3f3f3;
      background-image: url('../img/olives-home-product-bg.jpg');
    }
    .box-title {
      background-color: $fruit-color;
    }
  }
  .box-title {
    .svg {
      path {
        fill: $white;
      }
    }
  }
  .box-content {
    padding: rem-calc(140 0 150);
    @include breakpoint(small only) {
      padding: rem-calc(30 0 0);
    }
  }
}
.roses {
  color: $dark-roses-color;
  h1:not(.gray) {
    color: $dark-roses-color;
  }
  .svg {
    path {
      fill: $dark-roses-color;
    }
  }
}
.flower {
  color: $dark-flower-color;
  h1:not(.gray) {
    color: $dark-flower-color;
  }
  .svg {
    path {
      fill: $dark-flower-color;
    }
  }
}
.olives {
  color: $olives-color;
  h1:not(.gray) {
    color: $olives-color;
  }
  .svg {
    path {
      fill: $dark-olives-color;
    }
  }
  &.dark {
    color: $dark-olives-color;
    h1:not(.gray) {
      color: $dark-olives-color;
    }
    .svg {
      path {
        fill: $dark-olives-color;
      }
    }
  }
}
.senses {
  color: $dark-senses-color;
  h1:not(.gray) {
    color: $dark-senses-color;
  }
}
.fruit {
  color: $dark-fruit-color;
  h1:not(.gray) {
    color: $dark-fruit-color;
  }
}
h1.gray {
  color: $dark-gray;
}

.ms-section .fullscreen {
  @include breakpoint(medium) {
    width: 200%;
    height: 100%;
  }
}
.ms-left {
  .ms-section {
    overflow: hidden;
  }
}
.ms-right {
  .ms-section {
    overflow: hidden;
    .ms-tableCell {
      section {
        //right: 0;
      }
    }
    &.section0 {
      .ms-tableCell {
        .hero {
          @include breakpoint(medium) {
            transform: translateX(-50%);
          }
        }
      }
    }
    &.section0 {
      z-index: 5;
    }
    &.section1 {
      z-index: 4;
    }
    &.section2 {
      z-index: 3;
    }
    &.section3 {
      z-index: 2;
    }
    &.section4 {
      z-index: 1;
    }
  }
}

.SetPage {
  .category {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: rem-calc(75);
    @include breakpoint(small only) {
      padding-top: rem-calc(35);
      min-height: 50vh !important;
    }
    h1 {
      font-size: rem-calc(45);
    }
    h2 {
      @include breakpoint(small only) {
        font-size: rem-calc(25);
      }
    }
    p {
      font-size: rem-calc(18);
    }
    .more {
      display: inline-block;
      font-size: rem-calc(13);
      font-weight: bold;
      text-transform: uppercase;
      color: #bd1f4a;
      padding: rem-calc(15);
      position: relative;
      transition: all 0.2s ease;
      &:after {
        content: '';
        display: block;
        width: 0;
        height: 2px;
        position: absolute;
        background-color: #bd1f4a;
        opacity: 0;
        // background: url(../img/btn-more-arrow.png) no-repeat;
        // margin-left: 5px;
        transition: all 0.4s ease;
      }
      &:hover {
        opacity: 0.75;
        &:after {
          width: calc(100% - 30px);
          opacity: 0.75;
        }
      }
    }
    &.face {
      h2 {
        color: #b36f81;
      }
      div {
        color: #8a4157;
      }
    }
    &.body {
      h2 {
        color: #b96b82;
      }
      div {
        color: #904e7c;
      }
    }
    &.hair {
      h2 {
        color: #845a41;
      }
      div {
        color: #75482d;
      }
    }
    &.gifts {
      h2 {
        color: #aa678a;
      }
      div {
        color: #aa678a;
      }
    }
  }
  .hero.set {
    padding: rem-calc(100 0 90);
    background-size: cover;
    background-repeat: no-repeat;
    @include breakpoint(small only) {
      padding: rem-calc(60 0 55);
      img {
        max-height: 40px;
      }
    }
    svg, .svg {
      height: rem-calc(75);
      width: auto;
      @include breakpoint(small only) {
        width: 55vw;
        height: auto;
      }
    }
    .roses {
      .svg, svg {
        path {
          fill: $dark-roses-color;
        }
      }
    }
    .flower {
      .svg, svg {
        path {
          fill: $dark-flower-color;
        }
      }
    }
    .olives {
      .svg, svg {
        path {
          fill: $dark-olives-color;
        }
      }
    }
    .fruit {
      img {
        @include breakpoint(medium) {
          max-width: 800px !important;
        }
      }
    }
  }
}

.ProductsGroupPage,
.PartnersPage,
.LatestPage,
.SingleNewsPage {
  background-color: #fafafa;
  .content {
    @include breakpoint(small only) {

    }
  }
  .owl-carousel {
    @include breakpoint(small only) {
      margin-bottom: rem-calc(50);
      background-color: $white;
    }
    .item {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      min-height: $owl-item-height;
      @include breakpoint(small only) {
        min-height: $owl-item-height-mobile;
      }
      &>.grid-x {
        .cell {
          @include breakpoint(medium) {
            min-height: $owl-item-height;
            padding: rem-calc(30 50);
          }
          @include breakpoint(large) {
            padding: rem-calc(40 60);
          }
        }
      }
      &>.row, &+.row, &>.grid-x, &+.grid-x {
        min-height: $owl-item-height;
        @include breakpoint(small only) {
          min-height: $owl-item-height-mobile-cta;
        }
        a {
          display: block;
          width: 100%;
        }
        h2 {
          color: #909090;
          font-size: rem-calc(45);
          margin-bottom: rem-calc(16);
          line-height: 1;
          // font-weight: bold;
          @include breakpoint(small only) {
            font-size: rem-calc(32);
            margin-bottom: rem-calc(15);
            line-height: 1.2;
            padding: rem-calc(0 16);
          }
        }
        p {
          color: #909090;
          font-size: rem-calc(18);
          margin-bottom: rem-calc(16);
          width: 100%;
        }
        .more {
          font-size: rem-calc(12);
          text-decoration: underline;
        }
        svg, .svg {
          height: rem-calc(45);
          width: auto;
        }
      }
      .texts {
        display: flex;
        flex-direction: column;
        // justify-content: center;
        // align-items: center;
        height: 100%;
        // text-align: center;
        .grid-x {
          justify-content: center;
          .cell {
            text-align: center;
          }
        }
      }
    }
    .texts {
      @include breakpoint(small only) {
        padding-top: rem-calc(15);
        padding-bottom: rem-calc(20);
      }
    }
    .owl-nav {
      position: absolute;
      width: 100%;
      top: 43%;
      //transform: translateY(-50%);
      .owl-prev,
      .owl-next {
        width: 46px;
        height: 50px;
        position: absolute;
        z-index: 1; 
      }
      .owl-prev {
        left: 0;
      }
      .owl-next {
        right: 0;
      }
    }
    .owl-dots {
      margin: 17px auto;
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
      @include breakpoint(small only) {
        bottom: -45px;
      }
      .owl-dot {
        float: left;
        margin-right: 9px;
        &:last-child {
          margin-right: 0;
        }
        span {
          width: 14px;
          height: 14px;
          border: 1px solid #e583ae;
          border-radius: 14px;
          background-color: transparent;
          display: block;
        }
        &.active {
          span {
            background-color: #e583ae;
            border-color: transparent;
          }
        }
      }
    }
  }
  .category-set {
    .filters {
      margin-top: rem-calc(80);
      @include breakpoint(small only) {
        margin-top: 0;
      }
      select {
        width: auto;
        display: inline-block;
        @include breakpoint(small only) {
          display: block;
          margin: rem-calc(12) auto;
          width: 75%;
        }
        [disabled="true"] {
          color: $medium-gray;
          background-color: #f1f1f1;
        }
      }
    }
    .product-items {
      padding-top: rem-calc(30);
      padding-bottom: rem-calc(60);
      .cell {
        margin-bottom: rem-calc(30);
      }
      .panel {
        position: relative;
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        border: 1px solid #d8d8d8;
        padding: rem-calc(30 40 20);
        box-shadow: 0 2px 2px rgba(0, 0, 0, .1);
        background-color: $white;
        transition: box-shadow 0.3s;
        margin-bottom: rem-calc(30);
        .image-wrapper {
          min-height: rem-calc(220);
          margin-bottom: rem-calc(30);
          display: flex;
          justify-content: center;
          img {
            transition: transform 0.3s ease;
          }
        }
        h3 {
          color: #333232;
          font-size: rem-calc(16);
          text-transform: uppercase;
          transition: color 0.3s;
        }
        p {
          color: #8e8e8e;
          margin-bottom: 0;
        }
        a {
          &:not(.button) {
            &:hover {
              h3 {
                color: #90ba39;
              }
            }
          }
          &.button {
            background-color: #90ba39;
            width: 100%;
            margin-top: rem-calc(15);
            text-transform: uppercase;
            position: relative;
            bottom: 0;
            left: 0;
            padding: 0.75em 1em;
            display: flex;
            align-items: center;
            justify-content: center;
            &:hover {
              background-color: $primary-color;
            }
            img {
              margin-right: 8px;
            }
          }
        }
        &:hover {
          box-shadow: 0 6px 18px rgba(0, 0, 0, .2);
          .image-wrapper {
            img {
              transform: scale(1.03);
            }
          }
        }
      }
    }
  }
}
#characteristics {
  option {
    color: $primary-color;
    &.disabled {
      color: $medium-gray;
    }
  }
}
#types,
#skins {
  [disabled="true"] {
    display: none;
  }
}
#FiltersMenu {
  @include breakpoint(small only) {
    padding-top: rem-calc(20);
    select {
      width: 100%;
    }
  }
}
.TaggedProductsPage {
  @extend .ProductsGroupPage;
}
.Product {
  @extend .ProductsGroupPage;
  background-color: #f1f7f8;
  .single-product {
    margin-bottom: rem-calc(50);
    @include breakpoint(medium) {
      margin-bottom: rem-calc(80);
    }
    h1 {
      font-size: rem-calc(24);
      text-transform: uppercase;
    }
    p.light {
      font-size: rem-calc(18);
    }
    .pack,
    .product-sku {
      font-size: rem-calc(14);
      color: #777777;
    }
    .product-description {
      margin-bottom: rem-calc(45);
      p {
        font-family: $header-font-family;
        font-size: rem-calc(14);
      }
    }
    .gallery {
      &>img {
        margin-bottom: rem-calc(32);
        @include breakpoint(medium) {
          margin-bottom: rem-calc(60);
        }
      }
    }
    .partner {
      width: rem-calc(120);
      height: rem-calc(120);
      margin-bottom: rem-calc(30);
      a {
        border: 0.75px solid #bbbbbb;
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
        background-color: rgba($primary-color, 0);
        box-shadow: 0 3px 6px rgba($black, 0);
        transition: background-color 0.3s, box-shadow 0.3s;
        &:hover {
          background-color: rgba($white, 0.2);
          box-shadow: 0 3px 6px rgba($black, 0.2);
        }
      }
    }
    .buy-here {
      font-size: rem-calc(24);
      text-transform: uppercase;
      margin-bottom: rem-calc(20);
    }
    /*.share-product {
      a {
        font-size: rem-calc(14);
        color: #777777;
        text-transform: uppercase;
        &:hover,
        &:focus {
          text-decoration: underline;
        }
        &:before {
          content: "";
          width: 14px;
          height: 13px;
          background: url(../img/share-icon.png) no-repeat;
          display: inline-block;
          margin-right: 8px;
        }
      }
    }*/
  }
  .ingredients {
    margin-bottom: rem-calc(110);
    .callout {
      padding: rem-calc(50 40);
      box-shadow: 0 2px 2px rgba(0, 0, 0, .1);
      border-radius: 1px;
      border-color: #d2d7ca;
      color: $black;
      @include breakpoint(small only) {
        padding: rem-calc(20 0);
      }
      h2 {
        color: #734258;
        font-size: rem-calc(36);
        margin-bottom: rem-calc(40);
      }
      h3 {
        font-size: rem-calc(24);
        margin-top: rem-calc(35);
        margin-bottom: rem-calc(30);
        @include breakpoint(small only) {
          margin-bottom: rem-calc(15);
        }
      }
      p {
        font-size: rem-calc(14);
        padding-left: rem-calc(15);
        padding-right: rem-calc(15);
        @include breakpoint(small only) {
          margin-bottom: rem-calc(35);
        }
      }
      .image-wrapper {
        margin: 0 auto;
        overflow: hidden;
        border-radius: 50%;
        width: rem-calc(200);
        height: rem-calc(200);
        border: 9px solid $white;
        box-shadow: 0 2px 32px rgba(0, 0, 0, .2);
        transition: box-shadow 0.3s;
      }
      &.roses {
        background-color: #f9eef5;
      }
      &.flower {
        background-color: #e1f3bf;
      }
      &.olives {
        background-color: #e1f3bf;
      }
      .columns {
        &:hover {
          .image-wrapper {
            box-shadow: 0 2px 10px rgba(0, 0, 0, .15);
          }
        }
      }
    }
  }
  .similar {
    margin-bottom: rem-calc(50);
    h2 {
      font-size: rem-calc(36);
    }
  }
}
.AboutusPage {
  .info {
    padding-top: rem-calc(100);
    padding-bottom: rem-calc(100);
    background: #9cd344;
    background: linear-gradient(90deg, #bae7d2 0%, #9cd344 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#bae7d2', endColorstr='#9cd344',GradientType=0 );
    .image-wrapper {
      overflow: hidden;
      img {
        border-radius: 15px;
      }
    }
    p {
      font-size: rem-calc(14);
    }
    h1 {
      font-family: $header-serif;
      font-size: rem-calc(32);
      font-style: italic;
    }
    h2 {
      font-size: rem-calc(26);
      letter-spacing: -1px;
    }
  }
  .brands {
    padding-top: rem-calc(60);
    padding-bottom: rem-calc(90);
    background-color: #f1f6fa;
    .panel {
      background-color: $white;
      padding: rem-calc(35 15 60);
      border: 1px solid #d5d6d8;
      box-shadow: 0 2px 2px rgba($black, 0.3);
      min-height: rem-calc(290);
      margin-bottom: rem-calc(30);
      & > div {
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      img, svg {
        max-width: 60%;
      }
    }
    p {
      margin-bottom: 0;//rem-calc(10);
      // line-height: 1.4;
      &:last-child {
        margin-bottom: 0;
      }
    }
    hr {
      width: rem-calc(65);
      margin-left: auto;
      margin-right: auto;
      border: none;
      height: 1px;
      background-color: #d5d6d8;
    }
  }
}
.ContactsPage {
  background-color: #f1f6fa;
  .contacts {
    padding-top: rem-calc(60);
    padding-bottom: rem-calc(90);
    h3 {
      font-size: rem-calc(24);
      margin-bottom: rem-calc(60)
    }
    p {
      margin-bottom: 0;
    }
    a {
      color: $black;
      &:hover {
        color: scale-color($black, $lightness: 60%);
      }
    }
    form {
      .actions {
        margin-top: rem-calc(15);
        &>.row {
          .columns {
            &:first-child {
              padding-left: 0;
            }
            &:last-child {
              padding-right: 0;
            }
          }
        }
        [type="submit"] {
          padding-left: rem-calc(40);
          padding-right: rem-calc(40);
        }
      }
    }
  }
  .map {
    padding: rem-calc(80 0 110);
    #gmap {
      min-height: rem-calc(400);
    }
  }
  .social {
    padding-bottom: rem-calc(90);
    .menu {
      display: inline-flex;
      li {
        a {
          &:hover {
            opacity: 0.5;
          }
        }
      }
    }
  }
}
.breadcrumbs {
  padding: rem-calc(50 0 25);
  a {
    text-decoration: underline;
  }
}
$color: #747474;
$rgb: 'rgb%28#{round(red($color))}, #{round(green($color))}, #{round(blue($color))}%29';
select {
  color: #747474;
  font-family: $body-font-family;
  font-size: rem-calc(14);
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='14' height='7' viewBox='0 0 14 7'><polygon points='7,7 0,0.6 0.7,0 7,5.8 13.3,0 14,0.6' style='fill: #{$rgb}'></polygon></svg>");
  background-size: 14px 7px;
  background-position: right -1.5rem center;
  box-shadow: 0 2px 2px rgba(0, 0, 0, .1);
  margin-right: rem-calc(12);
  padding-right: 2.1rem;
  transition: box-shadow 0.3s;
  &:last-child {
    margin-right: 0;
  }
  &:hover {
    box-shadow: 0 3px 6px rgba(0, 0, 0, .15);
  }
}
p {
  &.light {
    font-family: $header-font-family;
  }
}
.text-input {
  position: relative;
  [type="text"],
  [type="email"],
  textarea {
    padding: rem-calc(20 15);
    height: auto;
    box-shadow: none;
    margin-bottom: rem-calc(25);
  }
  span {
    &.error {
      position: absolute;
      color: red;
      right: rem-calc(15);
      top: rem-calc(20);
      //top: 50%;
      //transform: translateY(-50%);
    }
  }
}
.labels-wrapper {
  position: absolute;
  top: 25px;
  right: 40px;
  margin-bottom: 0;
  line-height: 1;
  display: flex;
  .label {
    background-color: transparent;
  }
}
.label {
  text-transform: uppercase;
  line-height: 1.2;
  margin-right: 4px;
  &:last-child {
    margin-right: 0;
  }
  img {
    max-height: 26px;
    width: auto;
  }
}


.partners {
  margin-top: rem-calc(45);
  margin-bottom: rem-calc(90);
  @include breakpoint(medium) {
    margin-top: rem-calc(55);
    margin-bottom: rem-calc(140);
  }
  .partner {
    margin-bottom: rem-calc(30);
    .card {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-item: center;
      background-color: $white;
      box-shadow: 0 5px 9px 0 rgba(0, 0, 0, 0.15);
      margin-bottom: rem-calc(20);
      height: 100%;
      min-height: rem-calc(300);
      .card-divider {
        padding: rem-calc(25 0 0 25);
        position: absolute;
        top: 0;
        h5 {
          font-size: rem-calc(18);
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      .card-section {
        display: flex;
        // height: 100%;
        justify-content: center;
        align-items: center;
        padding: rem-calc(25);
      }
    }
  }
  .text {
    margin-bottom: rem-calc(50);
    p {
      text-align: center;
      font-size: rem-calc(18);
    }
  }
}

.actual {
  margin-top: rem-calc(25);
  margin-bottom: rem-calc(130);
  @include breakpoint(medium) {
    margin-top: rem-calc(30);
  }
  .text-top {
    // text-align: center;
    padding-bottom: rem-calc(10);
  }
  .product {
    margin-top: rem-calc(100);
    &:nth-child(2) {
      margin-top: rem-calc(30);
    }
    .product-image {
      display: flex;
      @include breakpoint(medium) {
        width: 100%;
      }
      justify-content: center;
      align-items: center;
      background-color: #f1f1f1;
      box-shadow: 5px 5px 7px 0 rgba(0, 0, 0, 0.1);
      margin-bottom: rem-calc(30);
      padding: rem-calc(15);
      @include breakpoint(medium) {
        margin-bottom: 0;
        padding: rem-calc(25);
        min-height: 400px;
      }
      .image {
        @include breakpoint(small only) {
          display: flex;
          justify-content: center;
          align-items: center;
          // padding: 10px 0;
          &:after {
            content: "";
            display: block;
            padding-bottom: 100%;
          }
        }
      }
      img {
        // width: 75%;
        @include breakpoint(medium) {
          width: 100%;
        }
        transition: transform 0.3s;
        &:hover {
          transform: scale(1.1);
        }
      }
    }
    .product-info {
      h4 {
        font-size: rem-calc(24);
        font-weight: 700;
        margin-bottom: rem-calc(25);

      }
      p {
        margin-bottom: rem-calc(5);
      }
    }
  }
}
.shop-button {
  width: 100%;
  @include breakpoint(medium) {
    width: 40%;
  }
  margin-top: rem-calc(30);
  margin-bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $white;
  border: solid 1px #83bd04;
  color: $black;
  &:hover,
  &:focus {
    background-color: #83bd04;
    color: $white;
    svg {
      path {
        fill: $white;
      }
    }
  }
  svg {
    width: 24px;
    height: 21px;
    margin-right: rem-calc(15);
    path {
      transition: fill 0.25s;
    }
  }
}

.pop-up {
  display: none;
  box-shadow: 10px 10px 20px 0 rgba(0, 0, 0, 0.08), -10px -10px 20px 0 rgba(0, 0, 0, 0.1);
  max-width: rem-calc(1260);
  @include breakpoint(medium) {
    background-color: #f7f7f7;
  }
  & > .grid-x {
    background-color: $white;
    @include breakpoint(medium) {
      background-color: #f7f7f7;
    }
  }
  @include breakpoint(small only) {
    padding-left: rem-calc(20);
    padding-right: rem-calc(20);
    &.fancybox-content {
      padding: 0;
    }
  }
  @include breakpoint(medium) {
    width: 75vw;
  }
  .green-bar {
    width: 100%;
    background-color: #83bd04;
    height: rem-calc(52);

  }
  @include breakpoint(small only) {
    .fancybox-close-small {
      position: absolute;
      color: $white;
      position: absolute;
      top: 5px;
      right: 0;
      padding: 0;
    }
  }
  .pop-up__left {
    border-bottom: solid 1px #dedede;
    padding-top: rem-calc(20);
    @include breakpoint(medium) {
      padding: rem-calc(0 20);
      border-right: solid 1px #dedede;
      border-bottom: none;
    }
  }
  .pop-up__right {
      @include breakpoint(medium) {
        padding: rem-calc(0 20);
      }
      h3 {
        text-transform: uppercase;
        font-weight: bold;
        font-size: rem-calc(24);
        text-align: center;
        margin-top: rem-calc(30);
      }
  }
  .pop-up__image {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: rem-calc(25);
    background-color: #f1f1f1;
    @include breakpoint(medium) {
      background-color: $white;
    }
  }
  .pop-up__info {
    p {
      font-size: rem-calc(14);
    }
    h3 {
      font-size: rem-calc(24);
      font-weight: 700;
      margin-bottom: rem-calc(25);
    }
  }
  .pop-up__shops {
    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: solid 1px #dedede;
      @include breakpoint(small only){
        flex-direction: column;
      }
      .button {
        @include breakpoint(medium) {
          width: auto;
        }
        padding-left: 2rem;
        padding-right: 2rem;
        &:focus,
        &:hover {
          svg {
            path {
              fill: $white;
            }
          }
        }
      }
    }
    img {
      max-width: rem-calc(100);
    }
  }
  @include breakpoint(medium) {
    button {
      width: rem-calc(70);
      height: rem-calc(70);
      right: 44px;
      top: 10px;
      svg {
        width: rem-calc(50);
        height: rem-calc(50);
      }
    }
  }
  .shop-button {
    margin-top: 1rem;
    @include breakpoint(small only) {
      width: 90%;
      margin-bottom: rem-calc(30);
    
    // @include breakpoint(medium) {
    //   width: auto;
    // }
      background-color: #83bd04;
      color: $white;
      &:focus,
        &:hover {
          background-color: #3e7823;
        }
      svg {
        path {
          fill: $white;
        }
      }
    }
  }
}
// .fancybox-is-open,
.fancybox-bg {
  background: $white;
  opacity: 0.6;
}

.content {
  margin-top: rem-calc(45);
  margin-bottom: rem-calc(90);
  @include breakpoint(medium) {
    margin-top: rem-calc(55);
    margin-bottom: rem-calc(140);
  }
  .text {
    text-align: center;
    margin-bottom: rem-calc(100);
    @include breakpoint(medium) {
      padding: rem-calc(0 100);
    }
  }
  .box-small {
    // box-shadow: 0 5px 9px 0 rgba(0, 0, 0, 0.15);
    @include breakpoint(medium) {
      max-height: rem-calc(440);
    }
    .img {
      overflow: hidden;
      position: relative;
      height: 100%;
    }
  }
  .box-big {
    // box-shadow: 0 5px 9px 0 rgba(0, 0, 0, 0.15);
    @include breakpoint(medium) {
      max-height: rem-calc(440);
    }
    .img {
      overflow: hidden;
      position: relative;
      height: 100%;
      img {
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  .smbx,
  .box-big {
    overflow: hidden;
    position: relative;
    a {
      text-decoration: underline;
      &:hover,
      &:focus {
        text-decoration: none;
      }
    }
    h5 {
      text-transform: uppercase;
      font-weight: bold;
    }
    p {
      font-size: rem-calc(14);
    }
    .textbr {
      position: absolute;
      bottom: 30px;
      right: 30px;
      text-align: right;
    }
    .texttl {
      position: absolute;
      top: 30px;
      left: 30px;
    }
    .texttr {
      position: absolute;
      top: 30px;
      right: 30px;
      text-align: right;
    }
    .textbl {
      position: absolute;
      bottom: 30px;
      left: 30px;
    }
    img {
      width: 100%;
      max-width: none;
    }
    .light{
      color: $white;
      a {
        color: $white;
      }
    }
    .dark {
      color: $black;
      a {
        color: $black;
      }
    }
  }
  .smbx {
    @include breakpoint(medium) {
      overflow: hidden;
      .img {
        img {
          position: relative;
          max-width: none;
          width: 100%;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
  .smbx,
  .bbx {
    box-shadow: 0 5px 9px 0 rgba(0, 0, 0, 0.2);
  }
  .grid-x {
    // margin-bottom: rem-calc(20);
    @include breakpoint(medium) {
      margin-bottom: rem-calc(30);
    }
    &.boxes {
      .box-small,
      .box-big {
        @include breakpoint(small only) {
          height: calc(100vw - 30px);
        }
      }
    }
    .cell {
      margin-bottom: rem-calc(22);
      @include breakpoint(medium) {
        margin-left: rem-calc(12);
        margin-right: rem-calc(12);
      }
    } 
  }
}

.subscribe {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: fixed;
  bottom: 30px;
  right: 15px;
  @include breakpoint(medium) {
    bottom: 60px;
    right: 60px;
  }
  z-index: 25;
  .subscribe-text {
    pointer-events: none;
    position: absolute;
    display: none;
    color: $white;
    background-color: #84bc02;
    margin-bottom: rem-calc(20);
    top: -82%;
    white-space: nowrap;
    p {
      font-size: rem-calc(14);
      text-transform: uppercase;
      margin-bottom: 0;
      padding: rem-calc(11 45);
    }
    &:after {
      content: "";
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 12px solid #84bc02;
      position: absolute;
      right: 31px;
    }
  }
  .subscribe-button {
    display: flex;
    width: rem-calc(61);
    height: rem-calc(61);
    @include breakpoint(medium) {
      width: rem-calc(82);
      height: rem-calc(82);
    }
    background-image: linear-gradient(138deg, #84bc02 -84%, #689400 161%);
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    @include breakpoint(medium) {
      &:before {
        content: "";
        width: rem-calc(66);
        height: rem-calc(66);
        border-radius: 50%;
        border: solid 1px $white;
        position: absolute;
        pointer-events: none;
      }
    }
    img {
      width: rem-calc(37);
      height: auto;
      @include breakpoint(medium) {
        width: rem-calc(40);
      }
    }
  }
  &:hover {
    .subscribe-text {
      display: block;
    }
  }
}

#SubscribeButton {
  a {
    display: block;
  }
}
#SubscribePanel {
  &.showme {
    display: block;
  }
  display: none;
  position: fixed;
  bottom: 30px;
  right: 15px;
  @include breakpoint(small only) {
    left: 15px;
  }
  margin: 0 auto;
  @include breakpoint(medium) {
    bottom: 60px;
    right: 60px;
  }
  z-index: 25;
  .close-button {
    right: rem-calc(20);
    top: rem-calc(15);
  }
}


.subscribe-form {
  max-width: 100%;
  background-color: $white;
  @include breakpoint(medium) {
    max-width: rem-calc(375);
    box-shadow: 0 3px 12px rgba($black, 0.15);
  }
  h2 {
    margin-top: rem-calc(30);
    font-family: 'Fira Sans';
    font-size: rem-calc(16);
    font-weight: bold;
    text-transform: uppercase;
  }
  p {
    font-family: 'Fira Sans';
    font-size: rem-calc(14);
    font-weight: 300;

  }
  .clear {
    margin-top: rem-calc(20);
    width: 60%;
    .button {
      width: 100%;
      background-color: #83bd04;
      color: $white;
      font-size: rem-calc(18);
    }
  }
}
.labels {
  text-align: start;
}
.subscribe-form {
  [type="text"], [type="email"] {
    color: $black;
    font-family: 'Fira Sans';
    font-size: rem-calc(12);
    font-weight: 300;
    &::placeholder {
      color: $black;
      font-family: 'Fira Sans';
      font-size: rem-calc(12);
      font-weight: 300;
    }
      border: none;
      box-shadow: none;
      border-bottom: solid 1px #83bd04;
      &:focus {
        border: none;
        border-bottom: solid 1px #83bd04;
        box-shadow: none;
      }
  }
  .mc-field-group {
    position: relative;
  }
  select {
    color: $black;
    font-family: 'Fira Sans';
    font-size: rem-calc(12);
    font-weight: 300;
    border: none;
    box-shadow: none;
    border-bottom: solid 1px #83bd04;
    &:hover, &:focus {
      border: none;
      box-shadow: none;
      border-bottom: solid 1px #83bd04;
    }

  }
  .show-for-small-only {
    img {
      padding-top: rem-calc(12);
    }
  }
}
#SubscribePanel {
  #mc_embed_signup {
    div.mce_inline_error {
      font-size: rem-calc(10);
      font-weight: normal;
      padding: 2px 5px;
      width: 100%;
      top: 100%;
      position: absolute;
    }
  }
}

.sticky-overlay {
  pointer-events: none;
  z-index: 11;
  position: fixed;
  bottom: 0;
  height: rem-calc(220);
  width: 100%;
  background-image: linear-gradient(to bottom, rgba($white, 0), rgba($white, 1) 95%);
  @include breakpoint(medium) {
    // display: none;
  }
}

.PartnersPage,
.LatestPage {
  .sticky-overlay {
    @include breakpoint(medium) {
      display: none;
    }
  }
}